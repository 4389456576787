<template>
  <AddonMenuItemEdit
    leaveRoute="addon-menu-item"
    editRoute="addon-menu-item-edit"
    selectMoreItemsText="Select Add On Items"
    selectMoreExclusionaryItemsText="Select Items to Exclude from Add On"
    emptyText="There does not appear to be any add on items, please click below to add some."
    emptyExclusionaryText="There does not appear to be any excluded add on items, please click below to add some."
    :allUpsellAddonItems="currentItems"
    :upsellAddonItem="addonItem"
    :title="title"
    :saveMethod="save"
    :deleteMethod="deleteItem"
  />
</template>

<script>
import AddonMenuItemEdit from "components/upsell/upselladdon_menu_item_edit.vue";
import { AddonDataHelper } from "mixins/upsell.js";
import { mapActions, mapGetters } from "vuex";

export default {
    mixins: [AddonDataHelper],
    components: {
        AddonMenuItemEdit,
    },
    computed: {
        title() {
            let item = this.addonItem;
            if (item === undefined) return "";
            return `Add On Items for ${item.display}`;
        },
        addonItem() {
            let addonItem = this.currentItems.find((el) => {
                return (
                    el.TargetID == `${this.$route.params.TargetID}`.replace("e", "") &&
          el.exclusionary == this.exclusionary
                );
            });

            if (addonItem === undefined) {
                return undefined;
            }

            return addonItem;
        },
        exclusionary() {
            return `${this.$route.params.TargetID}`.includes("e");
        },
    },
    methods: {
        save(dirtyUpsellPositions) {
            let waypointID = this.$route.params.store;

            return this.updateAddonItem({
                waypointID,
                item: {
                    ...this.addonItem,
                    positions: dirtyUpsellPositions,
                },
            });
        },
        deleteItem() {
            let waypointID = this.$route.params.store;
            return this.deleteAddonItem({
                waypointID,
                targetID: this.addonItem.TargetID,
                exclusionary: this.exclusionary,
            });
        },
        ...mapActions("UpsellStore", ["deleteAddonItem", "updateAddonItem"]),
    },
};
</script>

<style>
</style>
